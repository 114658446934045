<!--
  to use:
  paramFile : URL IMAGE: String
  activeUpload: To Active Upload Image Boolean: True, False
  runSaveFunction : Run Function from parent
  reSize: Boolean to active re size : true, false
-->
<template>
  <div>
    <div
      class="
        relative
        w-32
        h-32
        bg-gray-200
        border border-solid border-gray-300
        rounded-full
        overflow-hidden
      "
      id="avatarNazih"
    >
      <img
        :src="$checkAvatar(paramFile)"
        onerror="javascript:this.src='/no-avatar.png'"
        class="h-32 w-32 object-cover"
        id="avatar_upload"
        :alt="$auth.getFullName()"
      />
      <button
        class="
          bg-dokBlue-lighter
          absolute
          border-0
          bottom-0
          top-0
          h-full
          w-full
          animate-pulse
        "
        v-if="activeUpload"
      >
        <span class="text-dokBlue-ultra"
          ><i class="fas fa-file-upload fa-3x"></i
        ></span>
        <input
          type="file"
          title="Choisissez le fichier"
          class="
            absolute
            left-0
            right-0
            top-0
            bottom-0
            h-full
            w-full
            cursor-pointer
            opacity-0
          "
          @change="setAvatar"
        />
      </button>
    </div>
    <!-- Modal For Crop Image -->
    <modal
      :isActive="modal"
      :exitModal="
        () => {
          modal = false;
        }
      "
      :width="450"
      :heigh="450"
      :has-no-scroll="true"
      :hidden-header="true"
    >
      <div class="flex flex-col gap-y-4">
        <div class="flex items-center gap-x-2 justify-between px-2">
          <h3 class="text-2xl font-EffraR text-black">
            Modifier la photo de profil
          </h3>
          <dok-button bg="none" @click.native="modal = false">
            <img src="/svg/x.svg" />
          </dok-button>
        </div>
        <div class="px-2 max-h-400 overflow-y-scroll scroll">
          <cropper
            :src="img"
            @change="cropImg"
            :stencil-props="{
              previewClass: 'preview'
            }"
            stencil-component="circle-stencil"
            class="w-full"
            v-if="reSize"
          />
        </div>

        <div class="px-2">
          <dok-button
            type="submit"
            size="lg"
            custom-class="w-full font-EffraM"
            @click.native="uploadAvatarToServer"
          >
            Sauvegarder
          </dok-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";

// Crop image
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

// Modal
import modal from "@/views/global-components/modal";

export default {
  components: { modal, Cropper },
  data() {
    return {
      imageInformation: {}, // File Information
      binryFile: null, // Final File
      img: null, // Prview Image Crop
      modal: false // Modal Crop
    };
  },
  props: {
    paramFile: {
      type: String,
      required: false
    },
    activeUpload: {
      type: Boolean
    },
    runSaveFunction: {
      type: Function,
      required: false
    },
    reSize: {
      type: Boolean,
      required: true
    },
    successMsg: {
      type: String,
      required: false
    }
  },
  methods: {
    ...mapActions("user", ["UPDATE_AVATAR"]),
    bloobToFile(dataurl) {
      let arr = dataurl.split(","),
        mime =
          arr[0].match(/:(.*?);/) &&
          arr[0].split(",")[0].match(/:(.*?);/).length
            ? arr[0].split(",")[0].match(/:(.*?);/)[1]
            : "",
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], this.imageInformation.name, { type: mime });
    },
    async cropImg({ coordinates, canvas, image }) {
      this.binryFile = this.bloobToFile(canvas.toDataURL());
    },
    async setAvatar() {
      let avatar = document.getElementById("avatar_upload");

      let { mimes, fileType } = {
        mimes: ["image/gif", "image/jpeg", "image/png"],
        fileType: event.target.files[0]["type"]
      };

      if (!mimes.includes(fileType)) {
        return EventBus.$emit("flashDok", {
          type: "errorr",
          message: "Ce type de fichier n'est pas supporté"
        });
      }

      this.imageInformation = event.target.files[0];
      this.img = URL.createObjectURL(event.target.files[0]);

      if (!this.reSize) {
        //console.log("UPLOAD DIRECTLY");
        return this.uploadAvatarToServer();
      }
      this.modal = true;
    },
    async uploadAvatarToServer() {
      this.$vs.loading();
      const reponseUploadFile = await this.UPDATE_AVATAR({
        file: this.reSize ? this.binryFile : this.imageInformation
      });
      this.onData(reponseUploadFile);
    },
    onData({ ok, fileName /*, path*/ }) {
      if (!ok) {
        EventBus.$emit("flashDok", {
          type: "errorr",
          message: "Erreur de téléchargement de l'image",
          timerAlert: 8000
        });

        return this.$vs.loading.close();
      }

      if (ok) {
        this.$emit("update:paramFile", fileName);

        if (typeof this.runSaveFunction !== "function") {
          if (ok) {
            this.$vs.loading.close();
            this.modal = false;
            return EventBus.$emit("flashDok", {
              type: "success",
              message: this.successMsg
                ? this.successMsg
                : "La photo de profil a été téléchargée avec succès"
            });
          } else {
            this.$vs.loading.close();
            this.modal = false;
            return EventBus.$emit("flashDok", {
              type: "errorr",
              message: "Erreur de téléchargement de l'image"
            });
          }
        }

        if (typeof this.runSaveFunction === "function") {
          this.runSaveFunction();
        }
        this.modal = false;
      }
      this.$vs.loading.close();
    }
  }
};
</script>

<style lang="scss">
#avatarNazih {
  button {
    @apply hidden;
  }

  &:hover {
    button {
      @apply block;
    }
  }
}

.preview {
  @apply border border-dashed border-dokBlue-ultra;
}
</style>
